<template>
  <div>
    <div class="flex py-6 px-8 w-full">
      <transition name="fade" mode="out-in">
        <div v-if="userProfile" class="flex flex-col sm:flex-row w-full">
          <card
            class="text-center sidebar sm:border-r border-gray-200 flex-none"
          >
            <loading :is-loading="isLoading || !userProfile" is-full></loading>
            <map-view
              :city="userProfile.user.addr3 || ''"
              v-if="userProfile.user"
            >
              <div
                v-if="
                  userProfile.history.length < 3 && userProfile.subscription
                "
                class="p-0"
              >
                <div
                  class="bg-yellow-100 p-2 px-2 justify-center items-center flex space-x-2 font-semibold uppercase rounded-t text-gray-600 text-center block"
                >
                  <font-awesome-icon
                    class="text-yellow-500"
                    fixed-width
                    :icon="['fad', 'star']"
                  />
                  <div>New Customer</div>
                </div>
              </div>
              <div class="pb-0">
                <div class="flex justify-center p-6 py-10">
                  <avatar :sad="isCancelled" :email="userProfile.user.name" />
                </div>
                <div class="flex justify-center py-2 mb-2">
                  <status-label
                    :status="userProfile.subscription.subscription"
                    v-if="isSubAvailable"
                  />
                </div>

                <div class="font-semibold text-lg">
                  {{ userProfile.user.name }}
                </div>

                <div class="text-gray-500 text-sm">
                  {{ userProfile.user.email }}
                </div>
                <div class="text-gray-500 text-sm mt-1">
                  joined {{ userProfile.user.joined | timeago }}
                </div>
              </div>
            </map-view>

            <div class="card-section mt-6 sm:mt-0">
              <cats-images :cats="userProfile.user.cats" class="mb-4" />
              <div v-if="userProfile.subscription">
                <div
                  class="text-lg px-10 sm:px-0 text-center sm:text-left text-gray-700"
                >
                  <span
                    class="font-semibold border-b-2 border-dotted text-indigo-500 border-indigo-500"
                    >{{ userProfile.subscription.subscription.monthsactive }}
                    monthly boxes
                  </span>
                  and donated
                  <span
                    class="font-semibold border-b-2 border-dotted text-indigo-500 border-indigo-500"
                    >{{ userProfile.user.pouchestoshelter }} pouches</span
                  >
                  to a cat shelter.
                </div>
              </div>
              <div class="pt-6" v-if="isAdmin">
                <dropdown-button no-dropdown is-full is-action>
                  <template v-slot:button
                    ><div class="text-center px-1 py-1 w-full cursor-pointer">
                      Create order...
                    </div></template
                  >
                  <div class="py-2">
                    <div class="text-xs py-2 pb-3 px-3 uppercase text-gray-500">
                      Choose order type...
                    </div>
                    <create-order icon="inbox" label="Taster Box" is-taster />
                    <create-order icon="box" label="Custom Box" />
                  </div>
                </dropdown-button>
              </div>
              <div class="divide-y">
                <user-trust-pilot class="py-4 mt-4" />
                <user-app-identify :app="currentPlatform" :app-info="appInfo" />
                <user-free-samples class="py-4" v-if="isSubAvailable" />
                <div class="flex flex-col py-4">
                  <div class="font-semibold mb-2 text-left">Subscription</div>
                  <div v-if="isSubAvailable">
                    <div class="flex justify-between items-center py-1">
                      <div class="text-gray-600">Status</div>
                      <div class="">
                        {{
                          userProfile.subscription.subscription.active
                            ? userProfile.subscription.subscription
                                .paymentpaused
                              ? 'Paused'
                              : 'Active'
                            : 'Cancelled'
                        }}
                      </div>
                    </div>

                    <div class="flex justify-between items-center py-1">
                      <div class="text-gray-600">Type</div>
                      <div class="">
                        {{
                          !userProfile.subscription.subscription.payday
                            ? '28 Day Rolling'
                            : 'Fixed Payment Date'
                        }}
                      </div>
                    </div>
                    <div class="flex justify-between items-center py-1">
                      <div class="text-gray-600">No. of cats</div>
                      <div class="">{{ userProfile.user.cats.length }}</div>
                    </div>
                    <div class="flex justify-between items-center py-1">
                      <div class="text-gray-600">Cost</div>
                      <div class="flex space-x-2">
                        <div
                          v-if="hasCoupon && !userProfile.coupons.onlyDelivery"
                        >
                          <font-awesome-icon
                            :icon="['fal', 'ticket-simple']"
                            class="text-sm text-indigo-600"
                          />
                        </div>
                        <div
                          :class="{
                            'line-through text-gray-500':
                              hasCredit ||
                              (hasCoupon && !userProfile.coupons.onlyDelivery)
                          }"
                        >
                          {{
                            userProfile.subscription.subscription.settings
                              .originalSubscription | formatPounds
                          }}
                        </div>
                        <div
                          v-if="
                            hasCoupon &&
                            !hasCredit &&
                            !userProfile.coupons.onlyDelivery
                          "
                        >
                          {{
                            userProfile.subscription.subscription.settings
                              .finalSubscription | formatPounds
                          }}
                        </div>
                        <div v-if="hasCredit">
                          {{
                            userProfile.subscription.subscription.settings
                              .finalSubscription | formatPounds
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-between items-center py-1">
                      <div class="text-gray-600">Last Updated</div>
                      <div class="">
                        {{
                          userProfile.subscription.subscription.lastchange
                            | timeago
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="rounded bg-gray-50 py-4 font-mono text-xs text-gray-400"
                  >
                    No active subscription
                  </div>
                </div>
                <div class="flex flex-col py-4">
                  <div class="font-semibold mb-2 text-left">Referrals</div>
                  <div
                    class="flex justify-center border rounded mb-2"
                    v-if="userProfile.referrals"
                  >
                    <div class="w-1/2 py-2 border-r">
                      <div class="font-semibold text-xl">
                        {{ userProfile.referrals.totalReferrals.length }}
                      </div>
                      <div class="text-gray-500 text-sm">Referrals</div>
                    </div>
                    <div class="py-2 flex-grow">
                      <div class="font-semibold text-xl">
                        {{ userProfile.referrals.totalConversions.length }}
                      </div>
                      <div class="text-gray-500 text-sm">Conversions</div>
                    </div>
                    <div
                      class="absolute self-center justify-self-center border text-xs bg-white rounded p-1 px-1 text-gray-600"
                    >
                      {{ referralConversion }}%
                    </div>
                  </div>

                  <div
                    class="flex justify-between items-center py-1"
                    v-if="userProfile.user"
                  >
                    <div class="text-gray-600">Referral code</div>
                    <div class="">{{ userProfile.user.referral }}</div>
                  </div>
                </div>
                <user-credit class="py-4" />
              </div>
            </div>
          </card>
          <card class="flex-grow overflow-y-auto">
            <loading
              :is-loading="isLoading || !userProfile"
              is-full
              class="w-full"
            ></loading>
            <div
              v-if="userProfile.subscription && hasFailedPayment"
              class="px-4"
            >
              <div
                class="text-gray-700 bg-red-100 flex items-center space-x-2 w-full p-4 py-2 rounded"
              >
                <font-awesome-icon
                  class="text-red-400"
                  fixed-width
                  :icon="['fad', 'triangle-exclamation']"
                />
                <div>This user has a failed payment on their account</div>
              </div>
            </div>
            <tabs>
              <tab key="Activity" slot="activity" :selected="true">
                <activity-feed
                  :activity="userProfile.activity"
                  v-if="userProfile.activity"
                />
              </tab>
              <tab key="Subscription" slot="subscription">
                <user-subscription />
              </tab>
              <tab key="Order History" slot="order history">
                <order-history :history="userProfile.history" />
              </tab>
              <tab key="User" slot="user">
                <user-details :user="userProfile.user" />
              </tab>
              <tab
                key="Notes"
                :count="
                  userProfile.notes ? (userProfile.user.notes ? 1 : 0) : 0
                "
                slot="notes"
              >
                <user-notes />
              </tab>
              <tab key="Settings" v-if="isAdmin" slot="settings">
                <user-settings />
              </tab>
            </tabs>
          </card>
        </div>
        <div v-else class="flex items-center justify-center flex-grow">
          no user found
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/common/ui/Avatar';
import { mapGetters } from 'vuex';
import { subtract } from 'lodash/fp';
import Loading from '@/components/common/ui/Loading';
import StatusLabel from '@/components/common/ui/StatusLabel';
import Card from '@/components/common/ui/Card';
import MapView from '@/components/common/ui/Map';
import Tabs from '@/components/common/ui/tabs/Tabs';
import Tab from '@/components/common/ui/tabs/Tab';
import ActivityFeed from '@/components/common/ui/activity/ActivityFeed';
import UserSubscription from '@/components/common/ui/user/UserSubscription';
import UserDetails from '@/components/common/ui/user/UserDetails';
import UserSettings from '@/components/common/ui/user/UserSettings';
import UserNotes from '@/components/common/ui/user/UserNotes';
import OrderHistory from '@/components/common/ui/order/OrderHistory';
import UserCredit from '@/components/common/ui/user/UserCredit';
import UserTrustPilot from '@/components/common/ui/user/UserTrustPilot';
import moment from 'moment';
import utils from '@/utils';
import UserFreeSamples from '@/components/common/ui/UserFreeSamples.vue';
import DropdownButton from '@/components/common/ui/DropdownButton.vue';
import CreateOrder from '@/components/common/ui/order/CreateOrder.vue';
import CatsImages from '../../components/common/ui/CatsImages.vue';
import UserAppIdentify from '@/components/common/ui/user/UserAppIdentify.vue';

export default {
  metaInfo() {
    return {
      titleTemplate: `${
        this.userProfile.user ? this.userProfile.user.name : ''
      } Profile - CCC Admin`
    };
  },
  components: {
    Avatar,
    ActivityFeed,
    Card,
    MapView,
    StatusLabel,
    Tabs,
    Tab,
    Loading,
    UserSubscription,
    UserAppIdentify,
    OrderHistory,
    UserDetails,
    UserSettings,

    UserNotes,
    UserCredit,
    UserTrustPilot,
    UserFreeSamples,
    DropdownButton,
    CreateOrder,
    CatsImages
  },
  computed: {
    ...mapGetters({
      userProfile: 'getCurrentUserProfile',
      isAdmin: 'isAdmin'
    }),
    hasCredit() {
      return this.userProfile?.user?.credit > 0;
    },
    hasFailedPayment() {
      return this.userProfile?.subscription?.subscription?.paymentfailed;
    },
    priceCredit() {
      const newCredit = subtract(
        this.userProfile?.subscription?.subscription?.costInt,
        this.userProfile?.user?.credit
      );
      const val = newCredit / 100 < 0 ? 0 : newCredit / 100;

      return val.toLocaleString(undefined, { minmumSignificantDigits: 2 });
    },
    hasCoupon() {
      return this.userProfile?.subscription?.subscription?.coupons.length > 0;
    },
    referralConversion() {
      return utils
        .getPercentageOf(
          this.userProfile?.referrals?.totalReferrals.length,
          this.userProfile?.referrals?.totalConversions.length
        )
        .toFixed();
    },
    isSubAvailable() {
      return this.userProfile?.subscription?.subscription;
    },
    isCancelled() {
      return (
        !this.userProfile?.subscription?.subscription?.active ||
        !this.userProfile?.subscription?.subscription
      );
    },
    currentPlatform() {
      return this.userProfile?.user?.settings?.platformIos
        ? this.userProfile?.user?.settings?.platformIos
          ? 'apple'
          : 'android'
        : false;
    },
    appInfo() {
      return this.userProfile?.user?.settings?.platformDetails;
    }
  },
  data() {
    return {
      isLoading: true
    };
  },
  methods: {
    getStatus(status) {
      // const statuses = {
      //   active: "text-green-500 bg-green-200",
      //   paused: "text-gray-600 bg-gray-300",
      //   cancelled: "text-red-500 bg-red-200",
      //   trial: "text-yellow-500 bg-yellow-200"
      // };

      // return statuses[status];

      return status
        ? 'text-green-500 bg-green-200'
        : 'text-gray-500 bg-gray-200';
    },
    getTime() {
      return moment().format('hh:mm A');
    }
  },
  mounted() {
    const promises = [];
    promises.push(this.$store.dispatch('getUser', this.$route.params.id));
    promises.push(
      this.$store.dispatch('getUserCurrentSubscription', this.$route.params.id)
    );
    promises.push(
      this.$store.dispatch('getUserHistory', this.$route.params.id)
    );
    promises.push(
      this.$store.dispatch('getUserActivity', this.$route.params.id)
    );
    promises.push(
      this.$store.dispatch('getUserReferrals', this.$route.params.id)
    );

    Promise.all(promises)
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
  destroyed() {
    this.$store.commit('reset_user_profile');
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  @apply w-full sm:w-80;
}
</style>
