<template>
  <div class="flex flex-col">
    <div
      class="sticky top-0 bg-white z-20 px-6 default-transition"
      v-scroll-shadow
    >
      <div
        class="flex sm:items-center flex-col sm:flex-row sm:justify-between space-x-6"
      >
        <div class="text-xl font-semibold py-5 flex-none">
          Users
          <span class="text-gray-400 font-normal ml-1" v-if="users"
            >({{ Number(users.links.totalresults).toLocaleString() }})</span
          >
        </div>
        <div
          class="w-full sm:flex-grow relative flex items-center pb-5 sm:pb-0"
        >
          <input
            type="text"
            class="w-full icon-left-input"
            @input="userSearch"
            v-model="searchQuery"
            placeholder="Search by email, name or postcode..."
          />
          <div class="absolute right-4">
            <transition mode="out-in" name="fade">
              <throbber v-if="isSearching" class="z-10" color="light" />
            </transition>
          </div>
          <div class="absolute left-2">
            <font-awesome-icon
              :icon="['fal', 'magnifying-glass']"
              class="text-gray-400 text-lg"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="relative flex flex-col flex-grow mt-6 sm:mt-0">
      <loading :is-loading="isLoading" is-full class="flex-grow flex flex-col">
        <transition name="fade" mode="out-in">
          <div
            class="w-full overflow-x-auto"
            v-if="users && users.content.length > 0"
            key="results-users"
          >
            <div class="grid w-full template-cols px-6">
              <div class="table-head contents">
                <div class="px-2"></div>

                <div class="px-2">Email</div>
                <div class="px-2">Joined</div>
              </div>
              <router-link
                tag="div"
                :to="{
                  name: 'userProfile',
                  params: { user: user, id: user.id, tab: 'activity' }
                }"
                v-for="(user, index) in users.content"
                :key="`user-${user.id}-${index}`"
                class="cursor-pointer group flex items-center py-2 hover:bg-gray-50 contents table-item"
              >
                <div class="flex space-x-4 items-center px-2">
                  <avatar
                    size="sm"
                    :sad="!user.activeSubscription"
                    :email="user.name"
                  />

                  <div class="flex-grow">{{ user.name }}</div>
                  <status-label
                    v-if="user.isOnTrial && user.activeSubscription"
                    :icon="['calendar-day']"
                    :tooltip="getTrialLabel(user)"
                    :label="getTrialDays(user)"
                    color="indigo"
                    reverse
                  />
                  <status-label
                    v-if="
                      user.isIncomplete &&
                      !user.isOnTrial &&
                      !user.activeSubscription
                    "
                    :icon="['user-slash']"
                    tooltip="Incomplete profile"
                    color="gray"
                  />
                  <status-label
                    v-if="!user.activeSubscription && !user.isIncomplete"
                    :icon="['face-sad-tear']"
                    color="red"
                    tooltip="Cancelled"
                  />
                  <status-label
                    v-if="!user.activeSubscription && user.isOnTrial"
                    :icon="['face-sad-tear', 'calendar-day']"
                    color="red"
                    tooltip="Cancelled Trial"
                  />
                  <status-label
                    v-if="user.isPaused"
                    color="gray"
                    :icon="['circle-pause']"
                    tooltip="Paused"
                  />

                  <div class="text-gray-400 text-sm w-16 text-right">
                    #{{ user.id }}
                  </div>
                </div>

                <div class="text-gray-500 px-2">
                  {{ user.email }}
                </div>
                <div class="text-gray-500 px-2">
                  {{ user.joined | timeago }}
                </div>
              </router-link>
            </div>
            <div class="px-6">
              <pagination
                v-if="users"
                :links="users.links"
                update="set_users"
              />
            </div>
          </div>
          <div
            v-else
            key="no-results-users"
            class="flex-grow h-full flex items-center justify-center text-gray-400"
          >
            <div>
              There are no results for
              <span class="font-semibold">"{{ searchQuery }}"</span>
            </div>
          </div>
        </transition>
      </loading>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Avatar from '@/components/common/ui/Avatar';
import Pagination from '@/components/common/ui/pagination/Pagination';
import Throbber from '@/components/common/ui/Throbber';
import Loading from '@/components/common/ui/Loading';
import utils from '@/utils';
import StatusLabel from '@/components/common/ui/ColorLabel';
import { differenceInCalendarDays } from 'date-fns';
export default {
  components: {
    Avatar,
    Pagination,
    Throbber,
    Loading,
    StatusLabel
  },
  data() {
    return {
      debounce: null,
      searchQuery: '',
      isSearching: false,
      isLoading: true
    };
  },
  computed: {
    ...mapGetters({
      users: 'getUsers'
    })
  },
  methods: {
    userSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.isSearching = true;
        if (event.target.value) {
          this.searchQuery = event.target.value;
          // set a url param for search query
          utils.setUrlParameter('search', event.target.value);
          this.$store.dispatch('searchUsers', event.target.value).then(() => {
            this.isSearching = false;
          });
        } else {
          utils.removeUrlParameter('search');
          this.$store.dispatch('getUsers').then(() => {
            // remove the url param for search query using pure javascript

            this.isSearching = false;
            this.searchQuery = '';
          });
        }
      }, 600);
    },

    getTrialLabel(user) {
      if (user.isOnTrial) {
        const days = differenceInCalendarDays(
          new Date(user.trialEnd),
          new Date()
        );
        if (days > 0) {
          return `Trial ends in ${days} days`;
        } else {
          return 'Trial ends today';
        }
      }
    },
    getTrialDays(user) {
      if (user.isOnTrial) {
        const days = differenceInCalendarDays(
          new Date(user.trialEnd),
          new Date()
        );
        return days;
      }
    }
  },
  mounted() {
    if (utils.getUrlParameter('search')) {
      this.searchQuery = utils.getUrlParameter('search');
      this.$store
        .dispatch('searchUsers', utils.getUrlParameter('search'))
        .then(() => {
          this.isLoading = false;
        });
    } else if (utils.getUrlParameter('page')) {
      this.$store
        .dispatch('getUsers', utils.getUrlParameter('page') - 1)
        .then(() => {
          this.isLoading = false;
        });
    } else {
      this.$store.dispatch('getUsers').then(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.grid {
  &.template-cols {
    grid-template-columns:
      auto minmax(min-content, max-content)
      minmax(max-content, max-content);
  }
}
</style>
