<template>
  <div>
    <admin-dashboard :welcome="welcome" v-if="!isPacker" />
    <packers-dashboard :welcome="welcome" v-if="isPacker && !isAdmin" />
    <div
      v-if="isAdmin && isCustomerService"
      class="w-full h-full flex flex-col items-center justify-center text-3xl font-semibold space-y-6"
    >
      <div class="w-48"><img :src="`images/${catWelcome}.webp`" /></div>
      <div>{{ welcome }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { split, first, sample } from 'lodash/fp';

import AdminDashboard from '@/views/AdminDashboard.vue';
import PackersDashboard from '@/views/PackersDashboard.vue';
export default {
  components: {
    AdminDashboard,
    PackersDashboard
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      isAdmin: 'isAdmin',
      isCustomerService: 'isCustomerService',
      isTestingMachine: 'isTestingMachine',
      isPacker: 'isPacker'
    }),
    catWelcome() {
      return sample(['cat-astro', 'cat-laptop']);
    },
    welcome() {
      const now = new Date();
      const hour = now.getHours();
      let time;
      if (hour < 12) {
        time = 'Good morning';
      } else if (hour < 18) {
        time = 'Good afternoon';
      } else {
        time = 'Good evening';
      }

      return `${time}, ${this.firstName}`;
    },
    firstName() {
      return first(split([' '], this.currentUser.name));
    }
  },
  methods: {
    ...mapState({ defaultDays: state => state.churn.defaultDays })
  },
  mounted() {
    this.$store.dispatch('getPackingList');
  }
};
</script>

<style lang="css" scoped></style>
