<template>
  <div class="py-4">
    <div class="mb-2 flex items-center text-left font-semibold">
      <div class="flex-grow">App Information</div>
      <div class="flex space-x-2" v-if="app">
        <div
          class="flex flex-grow items-center justify-end text-xl"
          v-if="app === 'apple'"
        >
          <div class="flex items-center justify-center">
            <font-awesome-icon
              :icon="['fab', 'apple']"
              :class="app === 'apple' ? 'text-gray-600' : 'text-gray-300'"
            />
          </div>
        </div>
        <div class="flex-grow text-xl" v-if="app === 'android'">
          <div class="flex items-center justify-center">
            <font-awesome-icon
              :icon="['fab', 'android']"
              :class="app === 'android' ? 'text-gray-600' : 'text-gray-300'"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="app">
      <div class="flex w-full items-center justify-between py-1">
        <div class="text-gray-600">Device</div>
        <div class="">{{ details.deviceType }}</div>
      </div>
      <div class="flex w-full items-center justify-between py-1">
        <div class="text-gray-600">OS</div>
        <div class="">{{ details.os }} {{ details.osVersion }}</div>
      </div>
      <div class="flex w-full items-center justify-between py-1">
        <div class="text-gray-600">Build</div>
        <div class="">{{ details.build }}</div>
      </div>
    </div>
    <div v-else class="rounded bg-gray-50 py-4 font-mono text-xs text-gray-400">
      No App installed
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAppIdentify',
  props: {
    app: {
      type: String
    },
    appInfo: {
      type: String
    }
  },
  data() {
    return {
      details: {}
    };
  },

  mounted() {
    const details = this.appInfo.split(',');
    this.details = {
      build: details[0],
      os: details[1],
      osVersion: details[2],
      device: details[3],
      deviceType: details[4]
    };
  }
};
</script>

<style></style>
