<template>
  <div class="divide-y space-y-4">
    <div>
      <key-value label="Name" :value="product.name" />
      <key-value label="Short Name" :value="product.shortname" />
      <key-value label="Type" :value="getType(product.foodtype)" />
      <key-value
        label=""
        :value="product.foodcomplete ? 'Complete' : 'Complementary'"
      />
      <key-value label="SKU" :value="product.sku" />
      <key-value label="Increments" :value="product.increments" />
      <key-value label="Location" :value="product.stocklocation" />
      <!-- <key-value label="Price" :value="formatCurrency(product.exvat)" /> -->
      <key-value
        label="Status"
        :value="
          product.discontinued
            ? 'Discontinued'
            : product.available
              ? 'Available'
              : 'Unavailable'
        "
      />
      <key-value v-if="isAdmin && !product.discontinued">
        <product-discontinue :product="product" />
      </key-value>
    </div>
    <div class="py-4">
      <div class="font-medium pb-4 flex w-full items-center">
        <div class="flex-grow">Product Details</div>
        <update-product :product="product" />
      </div>
      <key-value label="Strapline" multiline :value="product.strapline" />
      <key-value label="Description" multiline :value="product.description" />
      <key-value label="Composition" multiline :value="product.composition" />
      <key-value label="Constituents" multiline :value="product.constituents" />

      <key-value
        label="Nutitional Additives"
        multiline
        :value="product.additives"
      />
      <key-value
        v-if="product.size"
        label="Size"
        multiline
        :value="`${product.size}g`"
      />
    </div>
  </div>
</template>

<script>
import KeyValue from '@/components/common/ui/KeyValue';
import ProductDiscontinue from '@/components/common/ui/products/ProductDiscontinue';
import { mapGetters } from 'vuex';
import UpdateProduct from '@/components/common/ui/products/UpdateProduct.vue';
export default {
  name: 'ProductOverview',
  components: {
    KeyValue,
    ProductDiscontinue,
    UpdateProduct
  },
  props: {
    product: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin'
    })
  },
  methods: {
    formatCurrency(val) {
      return this.$options.filters.formatPounds(val);
    },
    getType(type) {
      if (type === 'wet_tray') {
        return 'Tray';
      }
      if (type === 'wet_pouch') {
        return 'Pouch';
      }
      if (type === 'wet_can') {
        return 'Can';
      }
      if (type === 'wet_smooth') {
        return 'Smooth';
      }
      if (type === 'wet_tasty') {
        return 'Tasty Slices';
      }
      if (type === 'wet_chunk') {
        return 'Tasty Chunks';
      }
      if (type === 'dry') {
        return 'Dry Bag';
      }

      return '-';
    }
  }
};
</script>

<style></style>
