<template>
  <div
    ref="avatarWrapper"
    class="rounded-full overflow-hidden bg-white flex items-center"
    :class="styles"
  >
    <img ref="avatar" class="w-full" :src="avatar" />
  </div>
</template>

<script>
import md5 from 'md5';
import { createAvatar } from '@dicebear/core';
import { notionistsNeutral } from '@dicebear/collection';

export default {
  name: 'Avatar',
  props: {
    email: String,
    size: {
      type: String,
      default: 'lg'
    },
    sad: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: {
        base64: true
      }
    };
  },
  computed: {
    hash() {
      return md5(this.email.trim().toLowerCase());
    },
    avatar() {
      const avatar = createAvatar(notionistsNeutral, {
        seed: this.hash,
        glassesProbability: this.sad ? 0 : 10,
        eyes: this.sad
          ? ['variant01', 'variant04']
          : ['variant05', 'variant04'],
        brows: ['variant01', 'variant12', 'variant10', 'variant05'],
        brows: this.sad
          ? ['variant02', 'variant09']
          : ['variant01', 'variant12', 'variant07', 'variant11', 'variant10'],
        lips: this.sad
          ? ['variant07', 'variant18', 'variant20', 'variant04']
          : [
              'variant03',
              'variant10',
              'variant11',
              'variant12',
              'variant14',
              'variant15',
              'variant17',
              'variant22',
              'variant23',
              'variant25',
              'variant30'
            ],
        backgroundColor: this.sad
          ? ['ffb3c3', 'ffa6b3', 'ffbfc1', 'ffadab', 'ffbac0']
          : [
              'b6e3f4',
              'c0aede',
              'd1d4f9',
              'c1e4f9',
              'c0d8e0',
              'e4f8be',
              'c1f0c8',
              'aedde2',
              'bcd2ee',
              'e2c6ff',
              'c1e4f9',
              'baffc9',
              'bae1ff',
              'ffffba',
              'e0dab9',
              'e6e6fa',
              'f0fff0',
              'f0f8ff',
              'f0fff5',
              'f5f5dc'
            ]
      });

      return avatar.toDataUri();
    },
    styles() {
      if (this.size === 'xs') {
        return 'w-4 h-4';
      }
      if (this.size === 'sm') {
        return 'w-7 h-7';
      }
      if (this.size === 'md') {
        return 'w-10 h-10';
      }
      if (this.size === 'lg') {
        return 'w-14 h-14';
      }
      if (this.size === 'xl') {
        return 'w-16 h-16';
      }
      return 'w-16';
    }
  }
};
</script>

<style lang="css" scoped></style>
