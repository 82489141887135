<template>
  <div class="w-full h-full flex flex-col items-center justify-center">
    <div
      class="text-3xl relative flex-col items-center space-y-4 font-semibold justify-center container pt-14 w-full text-center"
    >
      <div class="inline-flex">
        <avatar :email="currentUser.name" size="lg" />
      </div>
      <div>{{ welcome }}</div>
    </div>
    <div class="flex-grow flex items-center">
      <div class="flex divide-x divide-x-2 border-2 rounded-xl">
        <div
          class="flex flex-col space-y-8 text-center w-72 p-2 h-72 cursor-pointer group"
          v-if="packingProgress"
          @click="startPacking('subscriptions')"
        >
          <div
            class="h-full rounded-l-lg group-hover:shadow-2xl flex items-center group-hover:text-white justify-center flex-col group-hover:bg-indigo-400"
          >
            <div class="flex-grow flex flex-col items-center justify-center">
              <div class="text-8xl font-semibold">
                {{ packingProgress.items.subscriptions.length }}
              </div>
              <div class="text-gray-600 group-hover:text-indigo-200">
                Subscriptions
              </div>
            </div>
            <div
              class="flex-inline mx-auto border-b-2 pb-1 mb-6 group-hover:border-white"
            >
              Start packing &rarr;
            </div>
          </div>
        </div>
        <div
          class="flex flex-col space-y-8 text-center w-72 p-2 h-72 cursor-pointer group"
          v-if="packingProgress"
          @click="startPacking('tasters')"
        >
          <div
            class="h-full rounded-r-lg group-hover:shadow-2xl flex items-center group-hover:text-white justify-center flex-col group-hover:bg-indigo-400"
          >
            <div class="flex-grow flex flex-col items-center justify-center">
              <div class="text-8xl font-semibold">
                {{ packingProgress.items.tasters.length }}
              </div>
              <div class="text-gray-600 group-hover:text-indigo-200">
                Taster Boxes
              </div>
            </div>
            <div
              class="flex-inline mx-auto border-b-2 pb-1 mb-6 group-hover:border-white"
            >
              Start packing &rarr;
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center mb-12" v-if="isPackingMachine">
      <div class="flex items-center space-x-2 mb-4">
        <div class="font-semibold">Printers</div>
        <div class="flex items-center space-x-2">
          <div
            class="w-2 h-2 flex-none rounded-full default-transition"
            :class="{
              'bg-green-400': qzStatus === 'active',
              'bg-yellow-400': qzStatus === 'connecting',
              'bg-gray-400': qzStatus === 'inactive'
            }"
          ></div>
          <div class="text-gray-400">{{ qzStatus | capitalize }}</div>
        </div>
      </div>
      <set-printers is-col v-if="qzActive" />
    </div>

    <div class="py-8">
      <router-link
        :to="{ name: 'logout' }"
        class="rounded bg-gray-100 hover:bg-gray-200 w-full py-3 px-4"
        >Logout</router-link
      >
    </div>
    <div class="h-20 w-full relative">
      <div
        class="inset-0 absolute -bottom-0 -ml-4 -mb-12 -mr-4 opacity-100 text-indigo-400"
        :style="{
          'background-image': 'url(images/cat-progress.svg)',
          'background-position': 'center'
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SetPrinters from '@/components/common/printer/SetPrinters';
import Avatar from '../components/common/ui/Avatar.vue';
export default {
  name: 'PackersDashboard',
  components: {
    SetPrinters,
    Avatar
  },
  props: {
    welcome: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      packingProgress: 'packingProgressGetter',
      qzStatus: 'getQzStatus',
      currentUser: 'currentUser',
      isPackingMachine: 'isPackingMachine'
    }),
    qzActive() {
      return this.qzStatus === 'active';
    }
  },
  methods: {
    ...mapActions({
      setProgress: 'setPackingProgress'
    }),
    startPacking(type) {
      this.$router.push({
        name: 'packingProfile',
        params: { id: this.packingProgress.items[type][0].dispatchid },
        query: { type: type }
      });
    }
  },
  mounted() {
    this.$store.dispatch('getPackingList').then(response => {
      this.setProgress(response);
    });
  }
};
</script>

<style></style>
