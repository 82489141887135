<template>
  <div class="space-y-4">
    <div class="space-y-4">
      <div
        v-if="hasError"
        class="bg-red-100 p-4 text-red-400 rounded mb-4 error-msg"
      >
        {{
          errorMessage
            ? errorMessage
            : 'There was an error printing the shipping label'
        }}. Try again
      </div>

      <div
        v-if="deliveryOverride && isAdmin && overrideMismatch"
        class="bg-yellow-100 text-sm text-center text-gray-800 font-normal rounded-md p-4 items-stretch"
      >
        This customer has a delivery override on their account, please respect
        this when choosing a delivery option where possible.<br />
        <br />
        Override delivery courier is
        <span class="font-semibold uppercase">{{ deliveryOverride }}</span>
      </div>
      <select-dropdown :class="{ 'pointer-events-none': isNi }">
        <select
          id="label-shipping-selector"
          v-model="activeShipping"
          :disabled="isNi || item.hasLabel"
        >
          <option
            :key="`${option.name}-option`"
            v-for="option in availableServices"
            :value="option"
          >
            {{ option.name }}
          </option>
        </select>
        <div class="absolute right-11 pointer-events-none">
          <shipped-with is-icon :service="activeShipping" />
        </div>
      </select-dropdown>

      <form @submit.prevent="getShippingLabel">
        <loading-button
          is-full
          secondary
          :is-loading="printingLabel"
          :is-enabled="true"
        >
          <div class="flex items-center w-full justify-center">
            <font-awesome-icon :icon="['fal', 'print']" class="mr-2" />
            {{ item.hasLabel ? 'Reprint' : 'Print' }} shipping label
          </div></loading-button
        >
        <reset-label :link="remove" v-if="item.hasLabel && isAdmin" />
      </form>
    </div>
  </div>
</template>

<script>
import LoadingButton from '@/components/common/ui/LoadingButton';
import { find } from 'lodash/fp';
import utils from '@/utils';
import { mapGetters, mapMutations } from 'vuex';
import ShippedWith from '@/components/common/ui/ShippedWith';
import SelectDropdown from '../ui/SelectDropdown.vue';
import ResetLabel from './ResetLabel.vue';
export default {
  props: {
    link: String,
    parcels: Number,
    weight: Number,
    user: Object,
    item: Object,
    remove: String,
    deliveryOverride: {
      type: String,
      default: null
    }
  },
  components: {
    LoadingButton,
    ShippedWith,
    SelectDropdown,
    ResetLabel
  },
  data() {
    return {
      printingLabel: false,
      hasError: false,
      errorMessage: null,
      activeShipping: null
    };
  },
  watch: {
    activeService: {
      immediate: true,
      handler(val) {
        if (val) {
          this.activeShipping = this.availableServices.find(
            s => s.key === val.key
          );
        }
      }
    },
    activeShipping(val) {
      if (val) {
        this.updateItem({
          key: 'shippingType',
          payload: val
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      services: 'deliveryServicesGetter',
      isAdmin: 'isAdmin',
      activeShippingSelections: 'activeShippingSelectionsGetter'
    }),
    overrideMismatch() {
      return this.deliveryOverride;
    },
    availableServices() {
      const type = this.item.isSample ? 'taster' : 'subscription';
      const services = this.services[type].filter(o => o.enabled);

      return services;
    },
    activeService() {
      if (!this.item.isSample && this.isNi) {
        return this.availableServices.find(as => as.key === 'YE48');
      }
      return this.item.shippingType;
    },
    isNi() {
      return utils.stringToBoolean(this.user.settings.isNi);
    }
  },

  methods: {
    ...mapMutations({
      updateItem: 'update_packing_item_key'
    }),
    getService(key) {
      const type = this.activeShippingSelections.find(t => t.key === key);
      return this.availableServices.find(s => s.key === type.serviceKey);
    },

    getShippingLabel() {
      this.hasError = false;
      this.printingLabel = true;

      this.$store
        .dispatch('getShippingLabel', {
          weight: this.weight,
          noOfParcels: this.parcels,
          link: this.link,

          service: this.activeShipping
        })
        .then(response => {
          this.printingLabel = false;
          this.printLabel(response.data);
          this.updateItem({
            key: 'hasLabel',
            payload: true
          });
        })
        .catch(error => {
          this.printingLabel = false;
          this.hasError = true;
          console.log('ERR label', error?.data?.error[0]);
          this.errorMessage = error?.data?.error[0]?.errorMessage || error;
          find(
            ['key', 'I_PL'],
            this.$store.state.core.packingChecklist
          ).userCan = true;
          console.log(`Error in executing ${error}`);
        });
    },
    printLabel(data) {
      this.$store
        .dispatch('print', {
          printer: 'label',
          data: data,
          type: this.activeShipping.type
        })
        .then(() => {
          console.log('successful print');
          find(
            ['key', 'I_PL'],
            this.$store.state.core.packingChecklist
          ).isCompleted = true;
          setTimeout(() => {
            this.printingLabel = false;
          }, 2000);
        })
        .catch(error => {
          this.printingLabel = false;
          this.hasError = true;
          console.log('ERR', error);
          this.errorMessage = error?.data?.error[0].errorMessage || error;
          find(
            ['key', 'I_PL'],
            this.$store.state.core.packingChecklist
          ).userCan = true;
        });
    }
  }
};
</script>

<style lang="css" scoped></style>
