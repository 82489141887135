<template>
  <div class="p-6">
    <div v-for="(value, key) in data" :key="`listgroup-${key}`">
      <div class="font-semibold mb-2">
        {{ key }}
      </div>
      <div class="divide-y">
        <div class="grid auto-cols-min whitespace-nowrap grid-cols">
          <div class="table-head contents">
            <div></div>

            <div class="w-full text-right">
              In Subs {{ isConsumable ? '(Estimate)' : '(Acurate)' }}
            </div>
            <div class="w-full text-right">Months Left</div>

            <div class="w-full text-center">
              <div>In Stock</div>
            </div>
            <div class="w-full text-right">
              <div>last updated</div>
            </div>
            <div class="w-full text-right">
              <div>location</div>
            </div>
          </div>
          <div
            v-for="product in orderTypes(value)"
            :key="`product-${product.id}`"
            class="group flex items-center py-2 hover:bg-gray-50 contents table-item"
          >
            <div class="w-full">
              <router-link
                :to="{
                  name: 'productProfile',
                  params: {
                    product: product,
                    id: product.id,
                    tab: 'overview'
                  }
                }"
                class="flex w-full items-center flex-none space-x-2"
              >
                <div class="w-8 h-8" v-if="showImage">
                  <img
                    class="object-contain"
                    :src="`/images/products/${product.sku}.webp`"
                  />
                </div>
                <div class="space-x-1 w-96 flex items-center flex-grow">
                  <div>{{ getName(product) }}</div>
                  <div v-if="product.foodcategory === 'kitten'">(Kitten)</div>
                  <div
                    class="text-gray-400 text-xs pr-2 italic flex-grow text-right"
                  >
                    {{ product.sku }}
                  </div>
                </div>
                <div class="flex space-x-2 w-32" v-if="!isConsumable">
                  <status-label
                    v-if="product.available"
                    label="Live"
                    tooltip="Live"
                    color="green"
                  />
                  <status-label
                    v-if="!product.available && !product.discontinued"
                    label="Unavailable"
                    tooltip="Unavailable"
                    color="yellow"
                  />
                  <status-label
                    v-if="product.discontinued"
                    label="Discontinued"
                    tooltip="Discontinued"
                    color="gray"
                  />
                  <status-label
                    v-if="product.popular"
                    label="P"
                    tooltip="Popular"
                    color="yellow"
                  />
                </div>
              </router-link>
            </div>
            <div
              class="w-full flex items-center space-x-2 justify-end text-sm text-gray-400"
              v-if="!product.discontinued"
            >
              <div v-if="!isConsumable">
                {{
                  formatValueFull(
                    Number(getStockStat(product.sku, 'active')),
                    product.foodtype,
                    product
                  )
                }}
              </div>
              <div v-else>
                {{
                  formatValueFull(
                    Number(getStockStat(product.sku, 'usedInLast30Days')),
                    product.foodtype,
                    product
                  )
                }}
              </div>
              <font-awesome-icon class="text-lg" :icon="['fal', 'abacus']" />
            </div>
            <div v-else></div>
            <div
              class="w-full flex items-center space-x-2 justify-end text-sm text-gray-400"
              v-if="!product.discontinued"
            >
              <div>
                {{ getMonthsRemaining(product) | numberFormat }}
              </div>
              <font-awesome-icon
                class="text-lg"
                :icon="['fal', 'calendar-day']"
              />
            </div>
            <div v-else></div>
            <div
              class="flex items-center space-x-8"
              v-if="!product.discontinued"
            >
              <stock-status :product="product" />
            </div>
            <div v-else></div>
            <div
              v-if="!product.discontinued"
              class="text-sm italic text-gray-400 flex justify-end items-center"
            >
              {{
                formatDate(
                  getStockStat(product.sku, 'lastStockCheck'),
                  'DD/MM/YYYY'
                )
              }}
            </div>
            <div v-else></div>
            <div
              v-if="!product.discontinued"
              class="text-sm italic text-gray-400 flex justify-end items-center"
            >
              <status-label
                v-if="product.stocklocation"
                :label="product.stocklocation"
                color="gray"
              />
              <div v-else>-</div>
            </div>
            <div v-else></div>
          </div>
          <div
            class="flex-grow flex-none text-sm text-right py-2 px-4 text-gray-400"
          >
            Totals
          </div>

          <div class="flex-none py-2 text-sm text-right text-gray-400">
            {{ getSum(value, 'insubs', key) }}
          </div>
          <div class="flex-none"></div>
          <div class="flex-none py-2 pl-4 text-sm text-left text-gray-400">
            {{ getSum(value, 'instock', key) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/utils';
import { orderBy, find, sumBy } from 'lodash/fp';
import StockStatus from './StockStatus.vue';
import StatusLabel from '@/components/common/ui/ColorLabel';
import { mapGetters } from 'vuex';
import tippy from 'tippy.js';
export default {
  components: { StockStatus, StatusLabel },
  name: 'StockList',
  props: {
    data: {
      type: Object
    },
    isConsumable: {
      type: Boolean,
      default: false
    },
    order: {
      type: Array,
      default: () => [
        ['discontinued', 'insubs'],
        ['asc', 'desc']
      ]
    },
    showImage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      stockStats: 'getCurrentStockStats'
    })
  },

  methods: {
    getMonthsRemaining(product) {
      const stat = this.stockStats.find(s => s.sku === product.sku);

      const instock = stat.instock <= 0 ? 0 : stat.instock;

      let remaining = utils.round(instock / stat.active, 0.5);
      if (this.isConsumable) {
        remaining = utils.round(instock / stat.usedInLast30Days, 0.5);
      }
      return (remaining = remaining || 0);
    },
    getStockStat(sku, type) {
      return this.stockStats.find(s => s.sku === sku)[type];
    },

    orderTypes(value) {
      value.forEach(v => {
        const stats = find(['sku', v.sku], this.stockStats);

        v.insubs = stats?.active;
        v.instock = stats?.instock;
      });

      return orderBy(this.order[0], this.order[1], value);
    },
    formatValue(val) {
      return val ? val / 800 : 0;
    },
    formatValueFull(value, type, product) {
      if (isNaN(value)) {
        return '-';
      } else {
        if (type === 'dry') {
          const val = value / product.increments;

          return val.toLocaleString();
        } else {
          return value.toLocaleString();
        }
      }
    },
    getSum(value, key, type) {
      let total = sumBy(function (o) {
        return o.discontinued ? 0 : o[key];
      }, value);
      if (total <= 0) {
        total = 0;
      }

      return type === 'Dry Food'
        ? this.formatValue(total, 'dry')
        : total.toLocaleString();
    },
    formatDate(date, format) {
      return utils.formatDate(date, format);
    },
    getName(product) {
      if (product.sku === 'T_TST_KIT') {
        return 'Taster Box (Kitten)';
      }

      if (product.foodtype === 'taster') {
        return product.description;
      }

      return product.name;
    }
  },
  mounted() {
    this.$nextTick(() => {
      tippy(`[data-tippy-content]`, {
        placement: 'left',
        arrow: false,
        delay: [400, 0],
        touch: 'hold'
      });
    });
  }
};
</script>

<style lang="scss" scoped>
.grid {
  &.grid-cols {
    grid-template-columns:
      auto minmax(min(140px, 140px), max-content) minmax(
        min(140px, 140px),
        max-content
      )
      minmax(max-content, max-content)
      minmax(max-content, max-content)
      minmax(min(80px, 80px), max-content);
  }
  &.grid-cols-consumables {
    grid-template-columns: auto minmax(min(140px, 140px), max-content) minmax(
        min(140px, 140px),
        max-content
      );
  }

  .table-item > * {
    @apply whitespace-nowrap min-w-min px-4 sm:px-0;
  }
  // grid-template-columns: 4fr 2fr 2fr 2fr 2fr;
}
</style>
